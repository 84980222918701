import { graphql } from '~/lib/dato/datocms/graphql.js';
import { BodyModuleFragment } from '../body/body.fragment.js';
import { ImageModuleFragment } from '../image/image.fragment.js';

export const CardModuleFragment = graphql(
  /* GraphQL */ `
    fragment CardModuleFragment on CardModuleRecord {
      id
      __typename
      image {
        responsiveImage(imgixParams: { w: 450, auto: format }) {
          ...ImageModuleFragment
        }
      }
      body {
        ... on BodyModuleRecord {
          id
          ...BodyModuleFragment
        }
      }
    }
  `,
  [BodyModuleFragment, ImageModuleFragment],
);
