import { graphql } from '~/lib/dato/datocms/graphql.js';

export const SpacerModuleFragment = graphql(
  /* GraphQL */ `
    fragment SpacerModuleFragment on SpacerModuleRecord {
      id
      __typename
      size
    }
  `,
  [],
);
