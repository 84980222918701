import { graphql } from '~/lib/dato/datocms/graphql';
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js';

export const AccordionItemModuleFragment = graphql(
  /* GraphQL */ `
    fragment AccordionItemModuleFragment on AccordionItemModuleRecord {
      id
      title
      text {
        value
      }
      expanded
    }
  `,
  [],
);

export const AccordionModuleFragment = graphql(
  /* GraphQL */ `
    fragment AccordionModuleFragment on AccordionModuleRecord {
      id
      __typename
      items {
        ...AccordionItemModuleFragment
      }
      singleExpand
    }
  `,
  [AccordionItemModuleFragment],
);
