import { graphql } from '~/lib/dato/datocms/graphql';
import { useQuery } from '~/composables/useQuery.js';
import type { TRedirects, TRedirectItem } from '~/lib/redirect/redirect.type';
import __projectConfig from '~/project.config.js';

export async function getRedirects(): Promise<TRedirects> {
  const query = graphql(
    /* GraphQL */ `
      {
        slugRedirect {
          id
          redirects
        }
      }
    `,
    [],
  );
  const redirectsResult = await useQuery(query);
  // @ts-ignore
  return redirectsResult?.value?.slugRedirect?.redirects;
}

export async function getRedirectDestination(
  path: string,
  locale: string = __projectConfig.locale.default,
): Promise<string | undefined> {
  // get the redirects from the CMS
  // @TODO          cache this
  const redirects = await getRedirects();

  // check from path if a locale is present
  // if so, set the locale to this value
  const potentialLocale = path.match(/^\/([a-z]{2})\//)?.[1];
  if (potentialLocale) {
    const availableLocales = __projectConfig.locale.locales.map((l) => l.code);
    if (availableLocales.includes(potentialLocale)) {
      locale = potentialLocale;
    }
  }

  // check if the locale is the default locale
  const isDefaultLocale = locale === __projectConfig.locale.default;

  // prepare the destination path
  let destination: string = path;

  // check if the path has a redirect
  for (let redirect of redirects) {
    const potentialSource = `/${isDefaultLocale ? '' : `${locale}/`}${redirect.source[locale]}`,
      potentialDestination = `/${isDefaultLocale ? '' : `${locale}/`}${redirect.destination[locale]}`;

    if (destination === potentialSource) {
      destination = potentialDestination;
    }
  }

  // if the destination is different from the current path, redirect
  if (destination !== path) {
    return destination;
  }
}
