import { useQuery } from '~/composables/useQuery.js';
import { graphql } from '~/lib/dato/datocms/graphql';
import { TagFragment } from '~/lib/dato/dato.js';

export async function getMetas() {
  const metasFragment = graphql(
    /* GraphQL */ `
      query RootQuery {
        _site {
          faviconMetaTags {
            ...TagFragment
          }
        }
      }
    `,
    [TagFragment],
  );
  return useQuery(metasFragment, {
    transform(data) {
      return data._site;
    }
  });
}
