import { graphql } from '~/lib/dato/datocms/graphql';
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js';

export const GridSectionFragment = graphql(
  /* GraphQL */ `
    fragment GridSectionFragment on GridSectionRecord {
      id
      __typename
      layout
      area1 {
        ...BlocksFragment
      }
      area2 {
        ...BlocksFragment
      }
      area3 {
        ...BlocksFragment
      }
      area4 {
        ...BlocksFragment
      }
      container {
        width
      }
    }
  `,
  [BlocksFragment],
);
