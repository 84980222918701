import { graphql } from '~/lib/dato/datocms/graphql';
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js';
import { MediaModuleFragment } from '~/components/media/media.fragment.js';

export const SliderModuleFragment = graphql(
    /* GraphQL */ `
      fragment SliderModuleFragment on SliderModuleRecord {
        id
        __typename
        slides {
            id
            __typename
            ... on MediaModuleRecord {
                ...MediaModuleFragment
            }
        }
      }
    `,
    [MediaModuleFragment],
  );
