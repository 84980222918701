import { buildRequestInit } from '@datocms/cda-client';
import type { TadaDocumentNode } from 'gql.tada';
import type { H3Event } from 'h3';
import __useQuerySubscription from './useQuerySubscription.js';

type Options<Variables> = {
  variables?: Variables;
};

export async function useQuery<Result, Variables>(
  query: TadaDocumentNode<Result, Variables>,
  options?: Options<Variables> & {
    transform?: (data: any) => any;
    event?: H3Event<any>;
  },
) {
  // if the query comes from the client, we make the request to the
  // server to make use of cache
  if (import.meta.client) {

    const userStore = useUserStore(),
      draftMode = userStore.isDraftMode,
      apiToken = userStore.apiToken;

    // protect the query from being executed without an API token
    if (!apiToken) {
      throw new Error('Client: Missing API token');
    }

    const response = await fetch('/api/cms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        options,
      }),
    });
    let json = await response.json();

    // execute transform function if provided
    if (options?.transform) {
      json = await options.transform(json);
    }

    // If we are in Draft Mode instead, and the composable is run client-side,
    // then we initiate the connection with the DatoCMS Real-time Updates API.
    if (draftMode) {
      return __useQuerySubscription<Result, Variables>({
        query,
        variables: options?.variables,
        token: apiToken,
        initialData: (await json).value,
        includeDrafts: true,
        excludeInvalid: true,
        async transform(data: any) {
          if (options?.transform) {
            return await options.transform(data);
          }
          return data;
        },
      }).data;
    }

    // return the result json
    return json;
  } else {

    const { default: UserStore }: any = await import('~/server/lib/user/user.store.js'),
      userStore = new UserStore(options?.event),
      apiToken = userStore.apiToken,
      draftMode = userStore.draftMode;

      console.log('TOKEN', apiToken);
    // protect the query from being executed without an API token
    if (!apiToken) {
      throw new Error('Server: Missing API token');
    }


    // make the request to DatoCMS
    const initRequest = buildRequestInit(query, {
      token: apiToken,
      includeDrafts: draftMode,
      excludeInvalid: true,
    });

    const responseJson = await (async () => {
      const response = await fetch('https://graphql.datocms.com/', {
        ...initRequest,
      });
      const json = await response.json();
      return json;
    })();

    // return the data
    return responseJson.data;
  }
}
