export * from './datocms/graphql.js';
export * from './datocms/recordInfo.js';
export * from './datocms/TagFragment.js';

import * as __layoutModel from './models/layout.model.js';
import * as __LocalModel from './models/locales.model.js';
import * as __MenuModel from './models/menus.model.js';
import * as __MetasModel from './models/metas.model.js';
import * as __PageModel from './models/pages.model.js';
import * as __RedirectsModel from './models/redirects.model.js';
import * as __SearchModel from './models/search.model.js';

const models = {
  metas: __MetasModel,
  pages: __PageModel,
  locales: __LocalModel,
  menus: __MenuModel,
  redirect: __RedirectsModel,
  search: __SearchModel,
  layout: __layoutModel
};

export { models };
