import { graphql } from '~/lib/dato/datocms/graphql';

export const LinkModuleFragment = graphql(
  /* GraphQL */ `
    fragment LinkModuleFragment on LinkModuleRecord {
      text
      link
    }
  `,
  [],
);
