import { graphql } from '~/lib/dato/datocms/graphql';
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.js';
import { BodyModuleFragment } from '~/components/ui/body/body.fragment.js';
import { HeroSectionFragment } from '~/components/hero/hero.fragment.js';
import { MediaModuleFragment } from '~/components/media/media.fragment.js';
import { AccordionModuleFragment } from '~/components/accordion/accordion.fragment.js';
import { CardModuleFragment } from '~/components/ui/card/card.fragment.js';
import { SpacerModuleFragment } from '~/components/ui/spacer/spacer.fragment.js';
import { SliderModuleFragment } from '~/components/slider/slider.fragment.js';

export const BlocksFragment = graphql(
  /* GraphQL */ `
    fragment BlocksFragment on RecordInterface {
      ... on RecordInterface {
        id
        __typename
      }
      ... on HeroSectionRecord {
        ...HeroSectionFragment
      }
      ... on BodyModuleRecord {
        ...BodyModuleFragment
      }
      ... on MediaModuleRecord {
        ...MediaModuleFragment
      }
      ... on AccordionModuleRecord {
        ...AccordionModuleFragment
      }
      ... on CardModuleRecord {
        ...CardModuleFragment
      }
      ... on SpacerModuleRecord {
        ...SpacerModuleFragment
      }
      ... on SliderModuleRecord {
        ...SliderModuleFragment
      }
    }
  `,
  [
    BodyModuleFragment,
    HeroSectionFragment,
    ImageModuleFragment,
    MediaModuleFragment,
    AccordionModuleFragment,
    CardModuleFragment,
    SpacerModuleFragment,
    SliderModuleFragment
  ],
);
