import { graphql } from '~/lib/dato/datocms/graphql';

export const ImageModuleFragment = graphql(/* GraphQL */ `
  fragment ImageModuleFragment on ResponsiveImage {
    src
    srcSet
    width
    height
    alt
    title
    base64
    sizes
  }
`);
