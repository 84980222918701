import { ButtonModuleFragment } from '~/components/ui/button/button.fragment.js';
import { graphql } from '~/lib/dato/datocms/graphql';

export const BodyModuleFragment = graphql(
  /* GraphQL */ `
    fragment BodyModuleFragment on BodyModuleRecord {
      suptitle
      title
      headingLevel {
        id
        level
      }
      subtitle
      lead
      text
      buttons {
        ... on ButtonModuleRecord {
          id
          ...ButtonModuleFragment
        }
      }
    }
  `,
  [ButtonModuleFragment],
);
