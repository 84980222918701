import { graphql } from '~/lib/dato/datocms/graphql';
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { type TMenu } from '~/components/menu/menu.type.js';
import { useQuery } from '~/composables/useQuery.js';

import { type TLayout } from '~/components/layout/layout.type.js';

export async function getLayout(name: string): Promise<TLayout> {
  const query = graphql(
    /* GraphQL */ `
      {
        layout(filter: {
          name: {
            eq: "${name}"
          }
        }) {
            id
            name
            logoHeader {
                url
            }
            logoFooter {
                url
            }
            logoPicto {
                url
            }
        }
      }
    `,
    [],
  );
  return useQuery(query, {
    transform: async (data) => {
      const layoutData: any = data.layout;

        if (!layoutData) return;

      if (layoutData.logo?.url && layoutData.logo?.raw !== '') {
        const req = await fetch(layoutData.logo.url),
          res = await req.text();
        layoutData.logo.raw = res;
      }

      if (layoutData.logoPicto?.url && layoutData.logoPicto?.raw !== '') {
        const req = await fetch(layoutData.logoPicto.url),
          res = await req.text();
        layoutData.logoPicto.raw = res;
      }

      const layout: TLayout = {
        name: layoutData.name,
        logoHeader: {
          src: layoutData.logo?.url,
          raw: layoutData.logo?.raw,
        },
        logoPicto: {
          src: layoutData.logoPicto?.url,
          raw: layoutData.logoPicto?.raw,
        },
      };

      return layout;
    },
  });
}
