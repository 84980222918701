export default function prepareMenu<T>(collection: any[]): any[] {
  const childItemsIds: string[] = collection
    .map((item: any) => item.children.map((child: any) => child.id))
    .flat();
  function buildMenu(rawMenu: any[]): T[] {
    return rawMenu.map((item: any) => {
      // it's a menu item
      // take it as is
      if (item.text) {
        return {
          id: item.id,
          text: item.text,
          link: {
            url: item.link.formatted.url,
            target: item.link.formatted.target,
          },
          items: item.children?.length ? buildMenu(item.children) : [],
        };
      }
      // it's a references item
      // take it from the root menu
      const rawItem: any = collection.find((rootItem: any) => rootItem.id === item.id);
      return {
        id: rawItem.id,
        text: rawItem.text,
        link: {
          url: rawItem.link.formatted.url,
          target: rawItem.link.formatted.target,
        },
        items: rawItem.children?.length ? buildMenu(rawItem.children) : [],
      };
    }) as T[];
  }
  const newCollection = buildMenu(collection).filter(
    (item: any) => !childItemsIds.includes(item.id),
  );

  return newCollection;
}
