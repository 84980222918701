import { graphql } from '~/lib/dato/datocms/graphql';
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { useQuery } from '~/composables/useQuery.js';
import { __camelCase } from '@lotsof/sugar/string';
import { type TMenu, type TMenuItem } from '~/components/menu/menu.type.js';

import __graphqlMenuIdPluralize from '../utils/graphqlMenuIdPluralize.js';

import __prepareMenu from '../utils/prepareMenu.js';

export function _allId(str: string): string {
  const firstLetter = str.split('').shift();
  const restLetters = __camelCase(str.slice(1, -1));
  const lastLetter = str.split('').pop();

  if (['i', 'y'].includes(lastLetter as string)) {
    return `all${firstLetter?.toUpperCase()}${restLetters}ies`;
  }
  if (['s'].includes(lastLetter as string)) {
    return `all${firstLetter?.toUpperCase()}${restLetters}es`;
  }
  if (['h'].includes(lastLetter as string)) {
    return `all${firstLetter?.toUpperCase()}${restLetters}hes`;
  }

  return `all${firstLetter?.toUpperCase()}${restLetters}s`;
}

export async function getMenu(id: string, locale: string): Promise<TMenu> {
  const allId = __graphqlMenuIdPluralize(id);
  const query = graphql(
    /* GraphQL */ `
      {
        ${allId}(locale: ${locale}) {
          id
          text
          link
          children {
            id
          }
          _status
        }
      }
    `,
    [],
  );
  return useQuery(query, {
    async transform(data) {
      
      const rootMenu = data[allId];

      // @ts-ignore
      if (!rootMenu) {
        return {} as TMenu;
      }

      const menu: TMenu = {
        id,
        locale,
        items: __prepareMenu<TMenuItem>(rootMenu),
      };

      return menu;
    },
  });
}
