import { graphql } from '~/lib/dato/datocms/graphql';
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { TagFragment } from '~/lib/dato/dato.js';
import { GridSectionFragment } from '~/components/grid/grid.fragment.js';
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js';
import { useQuery } from '~/composables/useQuery.js';

export async function getPage(slug: string, locale: string) {
  const query = graphql(
    /* GraphQL */ `
      {
        page(filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          _seoMetaTags {
            ...TagFragment
          }
          id
          title
          content {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
          _allSlugLocales {
            locale
            value
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment, TagFragment],
  );
  return useQuery(query, {
    // transform(data) {
    //   console.log('SD', data);
    //   return data.page;
    // }
  });
}
