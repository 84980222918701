import { graphql } from '~/lib/dato/datocms/graphql';
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.js';

export const MediaModuleFragment = graphql(
  /* GraphQL */ `
    fragment MediaModuleFragment on MediaModuleRecord {
      theme
      image {
        responsiveImage(imgixParams: { w: 1920, auto: format }) {
          ...ImageModuleFragment
        }
      }
    }
  `,
  [ImageModuleFragment],
);
