import { graphql } from '~/lib/dato/datocms/graphql';
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.js';
import { BodyModuleFragment } from '~/components/ui/body/body.fragment.js';

export const HeroSectionFragment = graphql(
  /* GraphQL */ `
    fragment HeroSectionFragment on HeroSectionRecord {
      theme
      backgroundImage {
        responsiveImage(imgixParams: { w: 1920, auto: format }) {
          ...ImageModuleFragment
        }
      }
      featuredImage {
        responsiveImage(imgixParams: { w: 1920, auto: format }) {
          ...ImageModuleFragment
        }
      }
      body {
        ... on BodyModuleRecord {
          ...BodyModuleFragment
        }
      }
      container {
        width
      }
      advanced
    }
  `,
  [BodyModuleFragment, ImageModuleFragment],
);
