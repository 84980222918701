import type { TLocales } from '~/components/locales/locales.type.js';
import { useQuery } from '~/composables/useQuery.js';
import { graphql } from '~/lib/dato/datocms/graphql';

export async function getLocales(): Promise<TLocales> {
  const localesFragments = graphql(
    /* GraphQL */ `
      query {
        _site {
          locales
        }
      }
    `,
    [],
  );
  const data = await useQuery(localesFragments);

  return {
    locales: data.value?._site.locales ?? [],
  };
}
