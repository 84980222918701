import { graphql } from '~/lib/dato/datocms/graphql';
import { useQuery } from '~/composables/useQuery.js';
import { GridSectionFragment } from '~/components/grid/grid.fragment.js';
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js';
import { buildClient as __buildClient, LogLevel as __logLevel } from '@datocms/cma-client-browser';

import { __deepFilter, __set, __deepSearch } from '@lotsof/sugar/object';

export type TSearchPagesPage = {
  slug: string;
  id: string;
  title: string;
  blocks: any[];
};

export type TSearchPages = TSearchPagesPage[];

export async function searchPages(search: string, locale: string): Promise<TSearchPages> {
  const query = graphql(
    /* GraphQL */ `
      {
        allPages(locale: ${locale}) {
          id
          title
          slug
          content {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment],
  );

  // query dato to get all pages
  // @TODO  add cache
  const pages = await useQuery(query);

  // @ts-ignore
  let pagesArray = pages?.value?.allPages;

  // filter pages by search
  pagesArray = pagesArray.filter((page: any) => {
    const res = __deepSearch(page, (item: any) => {
      if (item.key.endsWith('slug')) {
        return true;
      }
      if (typeof item.value !== 'string') {
        return false;
      }
      return item.value.match(new RegExp(search, 'i'));
    });
    if (res.slug && Object.keys(res).length > 1) {
      return true;
    }
    return false;
  });

  // return the results
  return pagesArray;
}
